@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import './layout.css';
@import './topbar.css';
@import './tooltip.css';
@import './home.css';
@import './media.css';
@import './projects.css';
@import './sidebar.css';
@import './MDEditor.css';

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --main-bg: black;
  --main-text: whitesmoke;
  --topbar-hover: #ff6700;
}

body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: var(--main-bg);
  color: var(--main-text);
}

body::-webkit-scrollbar {
  width: 0.4em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px var(--scrollbar-track);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.tooltip {
  position: relative;
}

.errorPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 10px;
}

.errorPage img {
  width: 100%;
  margin-top: 25px;
  text-align: center;
  background-color: var(--main-text);
  border-radius: 50px;
}

.errorPage a {
  color: var(--main-text);
}

.errorPage a:hover {
  color: var(--topbar-hover);
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
