.sidebar {
  position: fixed;
  height: calc(100vh - 50px);
  width: 200px;
  top: 50px;
  left: 0;
  z-index: 1000;
  padding: 20px 20px 0;
  background-color: var(--main-bg);

  border-right: 1px solid var(--main-text);
  transition-property: height, border, transform;
  transition-duration: 0.2s, 0.2s, 0.2s;
  transition-delay: 0s, 0s, 0s;
  transition-timing-function: linear;
}

.sidebar-maxWidth {
  width: 100vw;
  border-right: none;
}

.sidebar-shrink {
  height: 75px;
  transform: translateX(-150px);
  overflow: hidden;
  color: var(--main-bg);
  border-right: 1px solid var(--topbar-hover);
}

.sidebar-shrink .sidebar-caret:hover {
  color: var(--topbar-hover);
}

.sidebar-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.sidebar-caret {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 6px;
  height: 10px;
  width: 10px;
  cursor: pointer;
  font-size: larger;
  color: var(--main-text);
}

.sidebar-caret:hover {
  color: var(--topbar-hover);
}

.sidebar-header {
  font-family: 'Cinzel', 'Roboto', sans-serif;
  font-size: 1.5em;
  font-weight: bold;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.sidebarLinks {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sidebarLinks .sidebarLink {
  text-decoration: none;
  color: var(--main-text);
  font-weight: 500;
  padding: 2px 0;
}

.sidebarLinks .sidebarLink:hover {
  color: var(--topbar-hover);
}

.sidebarLinks .sidebarLink.active {
  color: var(--topbar-hover);
  font-weight: normal;
}
