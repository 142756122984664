.home {
  min-height: calc(100vh - 50px);
  width: 100%;
}

.home-bg {
  width: 100%;
  background-size: 300% 300%;
  background-image: linear-gradient(-45deg, rgba(59, 173, 227, 1) 0%, rgba(87, 111, 230, 1) 25%, rgba(152, 68, 183, 1) 51%, rgba(255, 53, 127, 1) 100%);
  animation: AnimateBG 20s ease infinite;
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.home-section {
  padding-top: 60px;
  display: flex;
  justify-content: center;
  color: whitesmoke;
}

.home-card-container {
  max-width: 1080px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.home-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(245, 245, 245, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin-top: 60px;
}

.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
}

.home-content > .title {
  font-family: 'Cinzel', 'Roboto', sans-serif;
  font-size: 4em;
  font-weight: bold;
}

.home-content > .subtitle {
  font-size: 1.8em;
}

.home-content > .subtitle > .little {
  font-size: 0.5em;
}

.home-card > .list-heading {
  font-size: 2em;
  margin-bottom: 10px;
}

.home-card > .list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style-type: none;
}

.home-card > hr {
  width: 90%;
  margin-bottom: 0.5em;
}

.home-card .list-item > .item {
  font-size: 1.8em;
}

.home-card > .list > .gap {
  margin-bottom: 0.5em;
}

.home-card > .list > p {
  font-size: 1em;
}

.home-card > .list > .list-item > .item > a {
  color: whitesmoke;
}

.home-content .master {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
  height: calc(100% - 100px);
  font-size: 1.5em;
  margin-top: 60px;
}
