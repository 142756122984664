.MDEditor-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* min-height: 100%; */
  position: relative;
}

.MDEditor {
  padding: 20px;
  height: calc(100vh - 120px - 50px);
}

.MDEditor #projects {
  align-self: center;
  text-align: center;
  width: 100%;
}

.MDEditor-container {
  position: relative;
}

.MDEditor-container .togglers {
  color: #c9d1d9;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
}

.MDEditor-container .togglers .toggler {
  padding-left: 6px;
  z-index: 1;
}

.MDEditor-container .dark-text {
  color: #000026;
}
