.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center; */
  width: 100%;
  min-height: 100%;
  position: relative;
}

.projects-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 100%;
}

.projects-footer {
  position: absolute;
  bottom: 0;
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.projects-footer hr {
  color: var(--color-text);
  width: 100%;
  margin-bottom: 10px;
}

.projects-footer .text {
  color: var(--color-text);
}

.projects-footer a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projects-footer a img {
  width: 50%;
}

@media screen and (max-width: 900px) {
  .projects-content {
    width: 100%;
  }
}
