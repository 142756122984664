.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--tooltip-bg);
  color: var(--tooltip-text);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  left: -130px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
