.topbarContainer {
  width: 100%;
  height: 50px;
  background-color: var(--main-bg);
  color: var(--main-text);
  position: sticky;
  top: 0;
  z-index: 100;
  margin: 0 auto;
}

.topbarContainer .topbar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.topbarContainer .topbar .topbarLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.topbarContainer .topbar .topbarLeft .logo {
  font-family: 'Cinzel', 'Roboto', sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  color: var(--main-text);
  text-decoration: none;
  margin-right: 20px;
}

.topbarContainer .topbar .topbarLeft .logo:hover {
  color: var(--topbar-hover);
}

.topbarContainer .topbar .topbarLeft .topbarLinks .topbarLink {
  font-family: 'Roboto', sans-serif;
  font-size: 0.9em;
  font-weight: 500;
  color: var(--main-text);
  text-decoration: none;
  margin-left: 10px;
}

.topbarContainer .topbar .topbarLeft .topbarLinks .topbarLink.active {
  color: var(--topbar-hover);
  font-weight: normal;
}

.topbarContainer .topbar .topbarLeft .topbarLinks .topbarLink:hover {
  color: var(--topbar-hover);
}

.topbarContainer .topbar .topbarRight .themeTogglerButton {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topbarContainer .topbar .topbarRight .themeTogglerButton:hover {
  color: var(--topbar-hover);
  cursor: pointer;
}
