@media screen and (max-width: 1100px) {
  .home-section {
    font-size: 0.8em;
  }

  .home-card-container {
    max-width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .home-section {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 600px) {
  .topbarContainer .topbar {
    font-size: 0.8em;
    padding: 0 10px;
  }

  .topbarContainer .topbar .topbarLeft .logo {
    margin-right: 10px;
  }

  .home-section {
    font-size: 0.5em;
  }

  .home-content .master {
    width: 90%;
  }
}

@media screen and (max-width: 300px) {
  .topbarContainer .topbar {
    font-size: 0.6em;
    padding: 0 10px;
  }

  .topbarContainer .topbar .topbarLeft .logo {
    margin-right: 10px;
  }

  .home-section {
    font-size: 0.4em;
  }

  .home-content .master {
    width: 90%;
  }
}
